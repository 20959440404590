<template v-if="user?.isSuperAdmin">
  <Popup />
  <div class="dashboard-view">
    <div class="sidebar-view">
      <ul class="navs">
        <li class="logo">
          <NuxtLink to="/">
            <NuxtImg
              src="/myrenderer-favicon.png"
              alt="MyRenderer Logo"
            />
          </NuxtLink>
        </li>
        <li
          v-for="nav in navItems"
          :key="nav.link"
        >
          <div class="list-bar">
            <v-tooltip
              activator="parent"
              location="right center"
            >
              {{ nav.text }}
            </v-tooltip>
            <NuxtLink
              :to="nav.link"
            >
              <v-icon size="2rem">
                {{ nav.icon }}
              </v-icon>
            </NuxtLink>
          </div>
        </li>
      </ul>

      <div class="profile">
        <v-tooltip
          activator="parent"
          location="right center"
        >
          {{ user?.email }}
        </v-tooltip>
        <v-icon size="2rem">
          mdi-account-star
        </v-icon>
      </div>
    </div>

    <div class="content-view">
      <NuxtPage />
    </div>
    <DialogContainer
      :id="dialog.id"
      v-for="dialog in dialogStore.dialogs"
      :key="dialog.id"
    />
  </div>
</template>

<script setup lang="ts">
const accountStore = useAccountStore()
const dialogStore = useDialogStore()
const user = computed(() => accountStore.user)
const navItems = [
  { link: '/admin', text: ' ダッシュボード', icon: 'mdi-tablet-dashboard' },
  // { link: '/admin/organizations', text: '組織一覧', icon: 'mdi-office-building' },
  { link: '/admin/users', text: '顧客管理', icon: 'mdi-account-group' },
]
</script>

<style lang="scss" scoped>
.dashboard-view {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  overflow: hidden;
  color: $white;
  background-color: $admin-background;
}

.dashboard-view > .sidebar-view {
  justify-content: space-between;
  gap: 3rem;
  height: 100%;
  padding: 0.5rem 0;
  font-size: 0.75rem;
  display: flex;
  text-align: center;
  flex-direction: column;
  background-color: $admin-primary;
  margin-right: 0.5rem;

  > .profile {
    bottom: 0;
    padding: 1.5rem;
    &:hover {
      background-color: $admin-secondary;
    }
  }

  > .navs {
    gap: 0.1rem;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;

    > .logo {
      font-size: 0.75rem;
      cursor: pointer;
      font-weight: bold;
      color: $admin-accent;
      max-width: 70px;
      margin: 0 auto;
      border-radius: 50%;

      > a > img {
        width: 100%;
      }
    }

    > li {
      > .list-bar {
        // 後ほどクラス名を付ける
        width: 100%;
        cursor: pointer;
        padding: 1.5rem;

        &.active {
          background-color: $admin-secondary;
        }

        &:hover {
          background-color: $admin-secondary;
        }

        > a {
          display: block;
        }
      }
    }
  }
}

.dashboard-view > .content-view {
  width: 100%;
  height: 100%;
}
</style>
